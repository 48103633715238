import React from 'react';
import CopyrightBeam from '../components/CopyrightBeam';
import './ProjectsPage.css';

const ProjectsPage = ({ projects, personalia, pageTitle }) => {
  return (
    <div className="projects-page">
      <h1 className="page-title">{pageTitle}</h1>
      <div className="project-grid">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <h2 className="project-title">{project.title}</h2>
            <div className="project-metadata">
              <span className="company-name">{project.company}</span>
              <span className="project-duration">{project.startDate} - {project.endDate}</span>
            </div>
            <div className="project-description">
              <ul className="description-list">
                {project.description.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="project-tech">
              <h3>Technologies Used:</h3>
              <div className="tech-tags">
                {project.technologies.map((tech, i) => (
                  <span key={i} className="tech-tag">{tech}</span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <CopyrightBeam 
        name={personalia.name}
        email={personalia.contactInfo.email}
        phone={personalia.contactInfo.phone}
        location={personalia.contactInfo.location}
      />
    </div>
  );
};

export default ProjectsPage;