import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import './CopyrightBeam.css';

const CopyrightBeam = ({ name, email, phone, location }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="copyright-beam">
      <div className="copyright-text">
        <span>©</span>
        <span>{currentYear}</span>
        <span>{name}</span>
        <span>All rights reserved.</span>
      </div>
      
      <div className="contact-info">
        <div className="contact-item">
          <AiOutlineMail className="contact-icon" />
          <span>{email}</span>
        </div>
        <div className="contact-item">
          <FaPhoneAlt className="contact-icon" />
          <span>{phone}</span>
        </div>
        <div className="contact-item">
          <FaLocationDot className="contact-icon" />
          <span>{location}</span>
        </div>
      </div>
    </div>
  );
};

export default CopyrightBeam;