import React, { useState, useRef, useEffect } from 'react';
import CopyrightBeam from '../components/CopyrightBeam';
import './ExperiencePage.css';

const ExperiencePage = ({ experiences, personalia, pageTitle }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  return (
    <div className="experience-page">
      <h1 className="page-title">{pageTitle}</h1>
      <div className="experience-timeline">
        {experiences.map((experience, index) => (
          <ExperienceCard
            key={index}
            experience={experience}
            index={index}
            isExpanded={expandedIndex === index}
            setExpanded={setExpandedIndex}
            isLast={index === experiences.length - 1}
          />
        ))}
      </div>
      <CopyrightBeam 
        name={personalia.name}
        email={personalia.contactInfo.email}
        phone={personalia.contactInfo.phone}
        location={personalia.contactInfo.location}
      />
    </div>
  );
};

const ExperienceCard = ({ experience, index, isExpanded, setExpanded, isLast }) => {
  const cardRef = useRef(null);

  const toggleExpand = () => {
    setExpanded(prev => prev === index ? null : index);
  };

  useEffect(() => {
    const updateLine = () => {
      if (cardRef.current) {
        const card = cardRef.current;
        const nextCard = card.parentElement.nextElementSibling?.querySelector('.experience-card');
        
        if (nextCard) {
          const cardRect = card.getBoundingClientRect();
          const nextCardRect = nextCard.getBoundingClientRect();
          const svg = card.querySelector('svg');
          
          if (svg) {
            const path = svg.querySelector('path');
            const isLeftToRight = index % 2 === 0;
            
            const startY = cardRect.height / 2;
            const endY = nextCardRect.top - cardRect.top;
            
            let width;
            if (isLeftToRight) {
              width = nextCardRect.left + (nextCardRect.width / 2) - cardRect.right;
            } else {
              width = -(cardRect.left - nextCardRect.left - (nextCardRect.width / 2));
            }

            const d = `M 0,${startY} 
                      h ${width * 0.4}
                      C ${width * 0.6},${startY} 
                        ${width * 0.8},${startY} 
                        ${width},${endY}`;
            
            path.setAttribute('d', d);
            svg.style.width = `${Math.abs(width)}px`;
            svg.style.height = `${endY + 20}px`;
          }
        }
      }
    };

    updateLine();
    window.addEventListener('resize', updateLine);
    
    const observer = new ResizeObserver(updateLine);
    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      window.removeEventListener('resize', updateLine);
      observer.disconnect();
    };
  }, [isExpanded, index]);

  return (
    <div className={`experience-container ${index % 2 === 0 ? 'left' : 'right'}`}>
      <div 
        ref={cardRef}
        className={`experience-card ${isExpanded ? 'expanded' : ''}`} 
        onClick={toggleExpand}
      >
        <div className="experience-header">
          <h2 className="role-title">{experience.role}</h2>
          <div className="company-info">
            <span className="company-name">{experience.company}</span>
            <span className="duration">{experience.startDate} - {experience.endDate}</span>
          </div>
        </div>
        
        <div className={`experience-content ${isExpanded ? 'show' : ''}`}>
          <div className="experience-description">
            <ul className="description-list">
              {experience.description.map((item, i) => (
                <li key={i} className={item.startsWith('    ') ? 'indented' : ''}>
                  {item.trim()}
                </li>
              ))}
            </ul>
          </div>
          {experience.achievements && experience.achievements.length > 0 && (
            <div className="achievements">
              <h3>Key Achievements:</h3>
              <ul className="description-list">
                {experience.achievements.map((achievement, i) => (
                  <li key={i}>{achievement}</li>
                ))}
              </ul>
            </div>
          )}
          {experience.skills && experience.skills.length > 0 && (
            <div className="skills">
              <h3>Skills:</h3>
              <div className="skills-container">
                {experience.skills.map((skill, i) => (
                  <span key={i} className="skill-tag">{skill}</span>
                ))}
              </div>
            </div>
          )}
        </div>

        {!isLast && (
          <div className={`connecting-line ${index % 2 === 0 ? 'left-to-right' : 'right-to-left'}`}>
            <svg className="curve-line">
              <path 
                fill="none" 
                stroke="#333" 
                strokeWidth="2"
                pathLength="1"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperiencePage;