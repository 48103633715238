import React from 'react';
import CopyrightBeam from '../components/CopyrightBeam';
import { AiOutlineMail } from 'react-icons/ai';
import { FaLinkedinIn } from "react-icons/fa6";
import './HomePage.css';

const HomePage = ({ home, personalia, language }) => {
  return (
    <div className="home-page">
      {/* Company Section */}
      <section className="section company-section">
        <div className="flex-container">
          <div className="image-container">
            <img
              src={home.companyImage}
              alt="Company"
            />
          </div>
          <div className="content-container">
            <h1 className="section-title">
              {language === 'en' ? 'Welcome to ' : 'Welkom bij '}{home.companyName}
            </h1>
            {home.companyDescription.map((paragraph, index) => (
              <p key={index} className="section-text">
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      </section>

     {/* Specializations Section */}
     <section className="section">
        <div className="content-container">
          <h2 className="section-title">
            {language === 'en' ? 'Core Specializations' : 'Kernspecialisaties'}
          </h2>
          <ul className="specializations-list">
            {home.specializations.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </section>

      {/* Personal Background Section */}
      <section className="section" id="about-me">
        <div className="flex-container about-me-container">
          <div className="content-container">
            <h2 className="section-title">
              {language === 'en' ? 'About me' : 'Over mij'}
            </h2>
            {home.personalBackground.map((paragraph, index) => (
              <p key={index} className="section-text">
                {paragraph}
              </p>
            ))}
          </div>
          <div className="image-container">
            <img
              src={personalia.profilePicUrl}
              alt="Personal"
            />
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="section">
        <div className="content-container contact-container">
          <h2 className="section-title">
            {language === 'en' ? 'Get in Touch' : 'Neem Contact Op'}
          </h2>
          <p className="section-text">{home.contactParagraph}</p>
          <div className="contact-buttons-container">
            <a
              href={`mailto:${personalia.contactInfo.email}`}
              className="contact-button"
            >
              <AiOutlineMail className="contact-icon" />
              {language === 'en' ? 'Mail' : 'Mail'}
            </a>
            <a
              href={personalia.contactInfo.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="contact-button"
            >
              <FaLinkedinIn className="contact-icon" />
              LinkedIn
            </a>
          </div>
        </div>
      </section>

      <CopyrightBeam 
        name={personalia.name}
        email={personalia.contactInfo.email}
        phone={personalia.contactInfo.phone}
        location={personalia.contactInfo.location}
      />
    </div>
  );
};

export default HomePage;