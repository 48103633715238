import { AiOutlineLinkedin } from 'react-icons/ai';
import React from 'react';

export const home = {
  companyName: "Ramaker Solutions",
  companyDescription: [
    "Hi, I'm Sjoerd, and welcome to Ramaker Solutions. As an engineering student, I've developed extensive knowledge in data analysis through my studies. While this foundation was largely theoretical, I wanted to put these skills to work in the business world - and that's how Ramaker Solutions began. The main focus of the company is to bridge the gap between raw data and strategic decisions.",
    "I help companies by combining analytical thinking with innovative problem-solving to optimize their processes and create sustainable solutions. My work includes building custom tools that save time and reduce human error through efficient back-end data transformations. I also provide market research services when needed. While data processing is my main focus, I'm always excited to take on new challenges. I enjoy learning new skills and finding more efficient solutions to complex problems (like building this website from scratch!).",
    "If you think I can help your company improve its processes or data handling, feel free to reach out through the contact section below."
  ],
  companyImage: "/images/Ramaker Solutions_E4.png",
  personalBackground: [
  "From an early age, I have been a curious person. Whether it was my father working in the garage or the neighbor being busy, I would always come by to ask what they were doing. They could expect the well-known question 'But why?' until everything was fully explained. This curiosity, interest in technology, and entrepreneurial mindset have ultimately led me to the master's program in Industrial Engineering and Management, where I am currently studying.",
  "As a reliable and enthusiastic person, I possess strong analytical skills that I can apply across different sectors. I am convinced that there are always opportunities to make processes more efficient, regardless of the company's size. My ambition is to apply this expertise primarily in the sustainability sector. Especially there, where innovation is so desperately needed, I want to make a difference with my knowledge and skills.",  
  "Furthermore, I am an active person who draws energy from sports and activities with friends. This energy translates into a competitive mindset where I do everything I can to avoid losing. Due to my curious nature, I enjoy trying new things. I also love going out to hike and discover beautiful places - it's precisely these moments in nature that show me why sustainable solutions are so important."
  ],
  specializations: [
    'Raw data transformation into actionable insights',
    'Future-proofing outdated data systems',
    'Back-end data structuring and optimization',
    'Custom automation script development',
    'Production workflow analysis',
    'Efficiency tool development'
  ],
  contactDescription: "Are you interested in a collaboration? Or do you just want to chat about my projects?",
  contactParagraph: "If you'd like to work together or have any questions, please feel free to reach out!",
  backgroundColor: '#f4f4f9',
  titleColor: '#1a1a1a',
  textColor: '#333',
  buttonColor: '#007bff',
  buttonTextColor: '#fff',
  socialLinks: [
    {
      url: 'https://linkedin.com/in/your-profile',
      color: '#0e76a8',
      icon: <AiOutlineLinkedin />,
    },
  ],
};

export const personalia = {
  name: 'Sjoerd Ramaker',
  profilePicUrl: '/images/profielfoto.jpg',
  bio: 'Hi, my name is Sjoerd. Website bouwen letsgoooo.',
  contactInfo: {
    email: 'info@ramakersolutions.com',
    phone: '+31 6 38004944',
    linkedin: 'https://www.linkedin.com/in/sjoerd-ramaker-61066b251/',
    location: 'Groningen, Netherlands',
  },
  logo: '/images/Ramaker Solutions_E4.jpg',
};

export const projects = [
  {
    title: "Production Planning Optimization",
    company: "Van der Graaf Drum Engines",
    startDate: "Jun 2023",
    endDate: "Aug 2023",
    description: [
      'Led a comprehensive analysis of production planning workflows to optimize operational efficiency',
      'Conducted stakeholder interviews to gather requirements and identify process bottlenecks',
      'Developed and implemented a production planning optimization tool'
    ],
    technologies: ["Excel", "Data Analysis"]
  },
  {
    title: "Data Preparation for New ERP System",
    company: "Van der Graaf Drum Engines",
    startDate: "Jun 2024",
    endDate: "Oct 2024",
    description: [
      'Setting up article file for semi-finished products',
      'Created an automated tool for intelligent article numbers generation and management',
      'Consolidated and normalized data from multiple legacy systems for ERP migration'
    ],
    technologies: ["Excel", "Data Integration Tools"]
  },
  // {
  //   title: "International Market Research - Japanese Labor Market",
  //   company: "International Business Research Groningen",
  //   startDate: "Apr 2024",
  //   endDate: "Jun 2024",
  //   description: [
  //     'Conducted comprehensive market research on Japanese labour market opportunities',
  //     'Developed strategic recommendations for Vietnamese workforce integration',
  //     'Investigated immigration policies and labour regulations',
  //     'Created a detailed business plan for market entry'
  //   ],
  //   technologies: ["Excel", "Research Tools"]
  // },
  {
    title: "Dynamic Survey Platform Development",
    company: "University of Groningen (COELO)",
    startDate: "Oct 2023",
    endDate: "Feb 2024",
    description: [
      'Designed and developed an adaptive survey platform with dynamic question routing',
      'Implemented personalized response integration for contextual surveying',
      'Created an intuitive user interface for enhanced response rates'
    ],
    technologies: ["Rstudio", "Form 'R", "Database Management"]
  },
  {
    title: "Renewable Energy Investment Analysis Tool",
    company: "University of Groningen",
    startDate: "Oct 2023",
    endDate: "Dec 2023",
    description: [
      'Developed a predictive model for ROI analysis of residential renewable energy systems',
      'Integrated real-world energy consumption patterns and cost variables',
      'Created scenario analysis tools for various solar and battery configurations'
    ],
    technologies: ["Matlab", "Data Analysis", "Financial Modelling"]
  }
];

export const experiences = [
  {
    role: "Strategic Location Analysis Specialist",
    company: "United Rentals",
    startDate: "Sep 2024",
    endDate: "Oct 2024",
    description: [
      'Conducted comprehensive market gap analysis across the Netherlands to identify underserved regions',
      'Performed detailed demographic and market research to identify potential client clusters',
      'Developed strategic recommendations for optimal facility locations based on client proximity and market potential'
    ],
    achievements: [],
    skills: ["Market Analysis", "GIS Mapping", "Data Analytics", "Strategic Planning"]
  },
  {
    role: "Data Processor",
    company: "Ramaker Solutions",
    startDate: "Jun 2023",
    endDate: "Present",
    description: [
      'Provide specialized data analysis and process optimization solutions for clients',
      'Design and implement custom data processing workflows and automation tools',
      'Deliver actionable insights through comprehensive data analysis reports'
    ],
    achievements: [],
    skills: ["Data Analysis", "Process Optimization", "Project Management", "Python", "R Studio", "Matlab", "Excel", "Consulting", "Tool Development"]
  },
  {
    role: "International Student Consultant",
    company: "International Business Research Project Groningen",
    startDate: "Oct 2023",
    endDate: "Sep 2024",
    description: [
      'Led comprehensive market research initiatives focusing on international labor markets',
      'Analyzed cross-border employment opportunities between Vietnam and Japan',
      'Developed detailed market entry strategies and business implementation plans'
    ],
    achievements: [],
    skills: ["Market Research", "Consulting", "International Business", "Data Analysis"]
  },
  {
    role: "Strategy Consulting Program Participant",
    company: "Expedition Strategy",
    startDate: "Nov 2023",
    endDate: "Nov 2023",
    description: [
      'Completed intensive training program with leading strategy consulting firms',
      'Developed practical consulting skills through real-world case studies and workshops',
      'Engaged with top-tier consultancies including KPMG, OC&C, Roland Berger, Bain & Company, Accenture, Strategy&, IG&H'
    ],
    achievements: [],
    skills: ["Strategic Analysis", "Problem Solving", "Business Case Development"]
  },
  {
    role: "Master's Studies, Industrial Engineering and Management",
    company: "University of Groningen",
    startDate: "Sep 2023",
    endDate: "Present",
    description: [
      'Advanced study of process analysis and optimization methodologies',
      'Focus on production system design and logistics management',
      'Research in operational efficiency and system optimization'
    ],
    achievements: [],
    skills: ["Process Analysis", "Operations Research", "Production Management"]
  },
  {
    role: "Pre-Master's Studies, Industrial Engineering and Management",
    company: "University of Groningen",
    startDate: "Sep 2022",
    endDate: "Jul 2023",
    description: [
      'Foundation studies in industrial engineering principles',
      'Coursework in process analysis and system optimization',
      'Preparation for advanced graduate studies'
    ],
    achievements: [],
    skills: ["Engineering Fundamentals", "Operations Research", "Production Management"]
  },
  {
    role: "Bachelor's Studies, Biomedical Engineering",
    company: "University of Groningen",
    startDate: "Sep 2019",
    endDate: "Jul 2022",
    description: [
      'Specialized in medical device innovation and development',
      'Bridged medical requirements with engineering solutions',
      'Thesis: Enhancement of Training Analysis Through Surface Electromyography',
      '    Developed novel muscle activity measurement protocols',
      '    Created automated analysis tools for training optimization'
    ],
    achievements: [],
    skills: ["Medical Device Design", "Biomechanics", "Signal Processing", "Research Methods"]
  }
];  